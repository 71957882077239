.maincontainer {
  font-family: "Karla", sans-serif;
  background-blend-mode: overlay;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  color: #FFFFFF;
  min-width: 100%;
  height: 100vh;
}

.progress-logo {
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .progress-logo {
    animation: progress-logo-spin infinite 3s linear;
  }
}

@keyframes progress-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.login-logo {
  padding-bottom: 40px;
}

.login-box-background {
  background-color: rgb(17, 24, 39);
}

.card {
  color: #000000;
}

.navbar-brand {
  margin-right: 0;
}

.navbarLogo {
  height: auto;
  max-height: 400px;
  width: auto;
  max-width: 400px;
}

.infoBoxInner {
  background-color: rgba(66, 66, 66, 0.7);
  border-radius: 10px;
}

.infoBoxInner2 {
  background-color: rgba(66, 66, 66, 0.5);
  border-radius: 10px;

}

.rotatedHeader {
  transform: rotate(-90deg);


  /* Legacy vendor prefixes that you probably don't need... */

  /* Safari */
  -webkit-transform: rotate(-90deg);

  /* Firefox */
  -moz-transform: rotate(-90deg);

  /* IE */
  -ms-transform: rotate(-90deg);

  /* Opera */
  -o-transform: rotate(-90deg);

  /* Internet Explorer */
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=3);

}

.iconButton:focus {
  outline: none;
}

.iconButton {
  box-shadow: "0 3px 10px rgb(0 0 0 / 0.2)";
}

.Center-Container {
  position: relative;
}

.Absolute-Center {
  width: 50%;
  height: 50%;
  overflow: none;
  margin: none;
  position: relative;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.spaceTop {
  margin-top: 100px;
}

.firebase-emulator-warning { display: none; }